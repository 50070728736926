
import { defineComponent, ref, watch } from 'vue'
import useModel from '@/hooks/useModel'
import { useStore } from 'vuex'
import moment from 'moment'
import Title from '@/views/LocalRegistration/components/title.vue'
import {
  dealerApplicableTypeClassMap as dealerApplicableTypeClassMapAPI,
  getAllProvince as getAllProvinceAPI,
  getCityListByProvinceId as getCityListByProvinceIdAPI,
  saveValidity as saveValidityAPI
} from '@/API/localRegistration'
import { getFirstLevelBU } from '@/utils'
import { message } from 'ant-design-vue'
import useWait from '@/utils/payment/useWait'

interface TypeClassValidityData {
  applicableTypeClass: string;
  logicType: 0 | 1;
  selectRestrictedCity: boolean;
  restrictedCityMonth: string;
  noRestrictedCityMonth: string;
  selectSpecialCity: boolean;
  specialCityMonth: string;
  otherCityMonth: string;
  specialCity: {
    province: string;
    provinceId: number;
    city: string;
    cityOptions: {id: number; name: string}[];
  }[];
}

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Title
  },
  emits: [
    'save'
  ],
  setup(props, { emit }) {
    const { state } = useStore()
    const wait = useWait()
    const visibleModel = useModel(props, 'visible')
    const publishDate = ref(moment())

    const activeKey = ref('')

    const provinceOptions = ref([])
    const data = ref<TypeClassValidityData[]>([])

    const handleClose = () => {
      data.value = []
      visibleModel.value = false
    }

    const handleAdd = (index: number) => {
      data.value[index].specialCity.push({
          province: '',
          provinceId: 0,
          city: '',
          cityOptions: [],
      })
    }

    const handleDelete = (index: number, specialCityIndex: number) => {
      data.value[index].specialCity.splice(specialCityIndex, 1)
    }

    const handleSelectProvince = async (index: number, specialCityIndex: number, value: string, id: number) => {
      data.value[index].specialCity[specialCityIndex].provinceId = id
      data.value[index].specialCity[specialCityIndex].cityOptions = (await getCityListByProvinceIdAPI({ provinceId: id}) as any).data
      data.value[index].specialCity[specialCityIndex].city = ''
    }

    getAllProvinceAPI().then(res => {
      provinceOptions.value = res as any
    })

    const buList = state.user.buList?.filter((item: any) => item.parentId === null)
    const buMap = new Map()
    for (const bu of buList) {
      buMap.set(bu.nameEn, bu.id)
    }

    const typeClassBuIdMap = new Map()
    const typeClasses: string[] = []

    if (buMap.size) {
      dealerApplicableTypeClassMapAPI().then(typeClassesMap => {
        for (const bu in typeClassesMap) {
          if (Array.from(buMap.keys()).includes(bu)) {
            typeClasses.push(...typeClassesMap[bu] as any)
            typeClassesMap[bu].forEach((typeClass: string) => {
              typeClassBuIdMap.set(typeClass, buMap.get(bu))
            })
          }
        }
        activeKey.value = typeClasses?.[0] ?? ''
        wait.toRun?.()
      })
    } 

    const init = async () => {
      await wait
      if (typeClasses.length === 0) {
        data.value = []
        return
      }
      data.value = typeClasses.map((item: string) => {
        return {
          applicableTypeClass: item,
          logicType: 0,
          selectRestrictedCity: false,
          restrictedCityMonth: '-',
          noRestrictedCityMonth: '-',
          selectSpecialCity: false,
          specialCityMonth: '-',
          otherCityMonth: '-',
          specialCity: [{
            province: '',
            provinceId: 0,
            city: '',
            cityOptions: [],
          }]
        }
      })
    }


    watch(visibleModel, (visible: any) => {
      if (visible) {
        init()
      }
    })

    const handleSave = async () => {
      const params: any = []
      let hasEmptyData = false
      for (const typeClassData of data.value) {
        const infoList: any = []
        if (typeClassData.selectRestrictedCity) {
          if (!['-', ''].includes(typeClassData.restrictedCityMonth) && !['-', ''].includes(typeClassData.noRestrictedCityMonth)) {
            infoList.push({
              activeStatus: true,
              cityCode: '',
              infoName: 'Restricted city',
              months: typeClassData.restrictedCityMonth,
              provinceCode: ''
            })
            infoList.push({
              activeStatus: true,
              cityCode: '',
              infoName: 'No Restricted city',
              months: typeClassData.noRestrictedCityMonth,
              provinceCode: ''
            })
          } else {
            hasEmptyData = true
          }
        }
        if (typeClassData.selectSpecialCity) {
          const specialCityInfoList: any = []
          if (!['-', ''].includes(typeClassData.specialCityMonth)) {
            for (const specialCityData of typeClassData.specialCity) {
              if (specialCityData.province !== '' && specialCityData.city !== '') {
                
                specialCityInfoList.push({
                  activeStatus: true,
                  cityCode: specialCityData.city,
                  infoName: 'Special city',
                  months: typeClassData.specialCityMonth,
                  provinceCode: specialCityData.province
                })
              } else {
                hasEmptyData = true;
              }
            }
          } else {
            hasEmptyData = true;
          }
          if (specialCityInfoList.length && !['-', ''].includes(typeClassData.otherCityMonth)) {
            infoList.push(...specialCityInfoList)
            infoList.push({
              activeStatus: true,
              cityCode: '',
              infoName: 'Other city',
              months: typeClassData.otherCityMonth,
              provinceCode: ''
            })
          } else {
            hasEmptyData = true
          }
        }
        if (infoList.length) {
          params.push({
            applicableTypeClass: typeClassData.applicableTypeClass,
            buId: typeClassBuIdMap.get(typeClassData.applicableTypeClass) ?? 0,
            infoList,
            logicType: typeClassData.logicType,
            publishDate: publishDate.value.format('YYYY-MM-DD')
          })
        }
      }
      if (!params.length || hasEmptyData) {
        message.error("Empty Data!")
        return
      }
      await saveValidityAPI(params)
      message.success("Save successfully!")
      emit('save', publishDate.value.format('YYYY-MM-DD'))
      handleClose()
    }

    return {
      visibleModel,
      handleClose,
      publishDate,
      activeKey,
      data,
      provinceOptions,
      handleAdd,
      handleDelete,
      handleSelectProvince,
      handleSave
    }
  }
})
