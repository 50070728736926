
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    nextTick,
    h,
    computed,
    watch,
} from 'vue';
import Title from '../components/title.vue';
import RegistrationValidtySetAdd from '@/views/LocalRegistration/Setup/RegistrationValidtySetAdd.vue';
import moment from 'moment';
import { Moment } from 'moment';
import {
    validityPublishDate as validityPublishDateAPI,
    getValidityList,
} from '@/API/localRegistration';
export default defineComponent({
    components: {
        Title,
        RegistrationValidtySetAdd,
    },
    setup() {
        const publishDates = ref<string[]>([]);
        const publishDate = ref<string>();
        const activeKey = ref<string>('1');
        const columns = [
            {
                title: 'Applicable tpye class',
                dataIndex: 'applicableTypeClass',
                key: 'applicableTypeClass',
            },
            {
                title: 'Restricted city',
                dataIndex: 'restrictedCity',
                key: 'restrictedCity',
            },
            {
                title: 'No Restricted city',
                dataIndex: 'noRestrictedCity',
                key: 'noRestrictedCity',
            },
            {
                title: 'Specical city',
                dataIndex: 'specialCity',
                key: 'specialCity',
            },
            {
                title: 'Other city',
                dataIndex: 'otherCity',
                key: 'otherCity',
            },
        ];

        const tableData = ref<any[]>([]);

        const validitySetDrawerVisible = ref(false);

        const showValiditySetDrawer = () => {
            validitySetDrawerVisible.value = true;
        };

        validityPublishDateAPI().then((data) => {
            publishDates.value = data.reverse();
            if (data.length) {
                publishDate.value = data[0];
            }
        });

        const handleSave = (date: string) => {
            publishDates.value.push(date);
            publishDates.value = publishDates.value
                .sort((a, b) => (a < b ? 1 : -1))
                .reverse();
            publishDate.value = date;
        };

        const fetchGetList = () => {
            getValidityList(publishDate.value as string).then((res: any) => {
                tableData.value = [];
                res.forEach((typeClassData: any) => {
                    const tableDataItem = {
                        applicableTypeClass: typeClassData.applicableTypeClass,
                        restrictedCity: '',
                        noRestrictedCity: '',
                        specialCity: '',
                        otherCity: '',
                    };
                    typeClassData.infoList.forEach((item: any) => {
                        let key = '';
                        switch (item.infoName) {
                            case 'Restricted city':
                                key = 'restrictedCity';
                                break;
                            case 'No Restricted city':
                                key = 'noRestrictedCity';
                                break;
                            case 'Special city':
                                key = 'specialCity';
                                break;
                            case 'Other city':
                                key = 'otherCity';
                                break;
                        }
                        if (item.detailList.length) {
                            tableDataItem[key] =
                                typeClassData.logicType === 0
                                    ? `${item.detailList[0].months} Months`
                                    : `Next year ${moment()
                                          .month(
                                              parseInt(
                                                  item.detailList[0].months
                                              ) - 1
                                          )
                                          .format('MMMM')}`;
                        }
                    });
                    tableData.value.push(tableDataItem);
                });
            });
        };

        watch(publishDate, (publishDate) => {
            if (publishDate) {
                fetchGetList();
            }
        });

        return {
            activeKey,
            columns,
            validitySetDrawerVisible,
            showValiditySetDrawer,
            tableData,
            publishDates,
            publishDate,
            handleSave,
        };
    },
});
